import React from "react"

import projectStyles from "./styles/projects.module.scss"

import ProjectCard from "./projectCard.js"


const myProjects = [
  {
    "index":1,
    "name":"Quantum CHSH Game",
    "description":"Simulation of quantum CHSH (XOR) games with any number of players",
    "link":"https://gitlab.com/simon.sekavcnik/quantum-chsh-numerical/"
  }
]

const projects = () => {

  const projectList = myProjects.map((project,index)=>{
    console.log(index);
    return(
      <ProjectCard
        key={"project-card-key".concat(index.toString())}
        name={project.name}
        description={project.description}
        link={project.link}
      />
    )
  })

  return(
    <>
    <div className={projectStyles.statement}>
      <h1> Projects </h1>
      <hr />
    </div>

    <div className={projectStyles.projectsContainer} >
      {projectList}  
    </div>
    </>
  )
}

export default projects

import React from "react"

import projectCardStyle from "./styles/projectCard.module.scss"


const projectCard = (props) => {
  return(
    <a href={props.link} rel="noopener noreferrer" target="_blank" className={projectCardStyle.container}>
    <div>
      <h3>{props.name}</h3>
      <hr/>
      <p>{props.description}</p>
    </div>
    </a>
  )
}


export default projectCard 

import React from "react"

import Layout from "../components/layout"

import { GatsbySeo } from 'gatsby-plugin-next-seo';
import Projects from "../components/projects"



const SecondPage = () => {
  return(
  <Layout>
    <GatsbySeo
        description="List of projects."
        openGraph={{
          url:'https://www.simon.sekavcnik.com/projects',
          title: "Projects",
          description: "List of projects.",
        }}/>
    <Projects/>
  </Layout>
  );
}

export default SecondPage
